import {Component, Provide, Vue, Watch} from 'vue-property-decorator';
import common from '../Common/Index.vue'
import { AccountLoginReq } from "@/interface/res/login";
import { AccountLoginApi } from "@/network/modules/login";
import { phone } from "@/utils/regExp";
import { mapGetters } from "vuex";
import PageRouter from "@/router/PageRouter";
import { RouteItem } from "@/interface/pagas/login";
import MineConfig from "@/router/modules/MineConfig";

@Component({
  components: {
    common
  },
  computed: {
    ...mapGetters(["userInfo"])
  }
})
export default class Login extends Vue {
  @Provide() form:AccountLoginReq = {
    accountName:"",
    password:"",
    device:"PC"
  };
  @Provide() userMsg: string = ''
  @Provide() passMsg: string = ''
  @Provide() loginLoading: boolean = false

  @Provide() routeLink:string = ''
  @Provide() routeItem:RouteItem = {name:"",config:"",key:"",}

  @Provide() newWindow:any = null

  @Watch('userInfo')
  private iDataChange():void {
    const _this:any = this;

    if(_this.$base.isNull(localStorage.getItem('_TOKEN')))return
    if(_this.$base.isNull(_this.routeLink)){
      _this.$base.goRouter(_this.$IndexConfig.home.path)
      return;
    }

    let code = _this.$route.query
    for (let codeKey in code) {
      code[codeKey] = _this.$decryptBy(code[codeKey])
    }

    delete code.r
    let codeStr = _this.$base.serializeStr(code)

    if(_this.routeItem.config == 'IndexConfig'){
      const link = _this.$base.routeLinkSplice(_this.$IndexConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
    if(_this.routeItem.config == 'LoginConfig'){
      const link = _this.$base.routeLinkSplice(_this.$LoginConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
    if(_this.routeItem.config == 'MineConfig'){
      const link = _this.$base.routeLinkSplice(_this.$MineConfig[_this.routeItem.key].path,codeStr)
      _this.$base.goRouter(link)
      return;
    }
  }

  created(){
    const _this:any = this;
    if(!_this.$base.isNull(_this.$route.query.r)){
      _this.routeLink = _this.$decryptBy(_this.$route.query.r)
      _this.routeItem = PageRouter.filter((item:any) => item.key == _this.routeLink)[0]
      console.log(_this.routeItem)
    }
    
    let getUrl=_this.getUrlParams(window.location.href);
      console.log(Object.keys(getUrl));
      if(Object.keys(getUrl).length>0&&getUrl.n=='jBmLRuCRmz79PrUj0w0w'){
        _this.form.accountName='18580888667';
        _this.form.password='jBmLR/uCR/mz79PrUj0w0w==';
        _this.loginFnNp();
      }
  }
  getUrlParams=(url:any)=> {
    const reg = /(\w+)=([^&]+)/g;
    const params:any = {};
    let match;
    
    while ((match = reg.exec(url)) !== null) {
      params[match[1]] = match[2];
    }
    
    return params;
  }


  /**
   * @Author HS
   * @Date 2021/8/26 9:51 上午
   * @Description: 微信登录
   * @Params: null
   * @Return: null
  */
  wechatLoginFn(){
    const _this:any = this;
    let url = `${process.env.VUE_APP_BASE_URL}shop/account/oauth/login/web?source=wechat_open&route=`
    
    if (_this.$route.query.r) {
      url += _this.$base.serializeStrTow(_this.$route.query)
    }
    console.log(url);
    _this.newWindow = window.open(url, "_blank");
  }

  /**
   * @Author HS
   * @Date 2021/6/30 5:08 下午
   * @Description: 账号密码登录
   * @Params: null
   * @Return: null
  */
  async loginFn(){
    const _this:any = this;
    if(!phone.test(_this.form.accountName)){
      _this.userMsg = "请输入正确的手机号码"
      return
    }
    _this.userMsg = ""

    if(_this.$base.isNull(_this.form.password)){
      _this.passMsg = "请输入密码"
      return
    }
    _this.passMsg = ""

    _this.loginLoading = true
    let params:AccountLoginReq = {..._this.form}
    params.password = _this.$adminEncryptBy(params.password)
    const res = await AccountLoginApi(params)
    let a = setTimeout(()=>{_this.loginLoading = false;clearTimeout(a)},500)
    const { code , data } = res
    if(code != 200 ){
      _this.passMsg = "用户名不存在或密码错误"
      return
    }
    _this.passMsg = ""
    // localStorage.setItem("_TOKEN",data.tokenValue)
    // _this.$store.dispatch('GetInfoFn',_this)

    _this.$store.commit('SET_TOKEN',data.tokenValue)
    _this.$store.commit('SET_COUNTNUM',1)
  }

  async loginFnNp(){
    const _this:any = this;
    
    _this.passMsg = ""

    _this.loginLoading = true
    let params:AccountLoginReq = {..._this.form}
    params.password = params.password
    const res = await AccountLoginApi(params)
    let a = setTimeout(()=>{_this.loginLoading = false;clearTimeout(a)},500)
    const { code , data } = res
    if(code != 200 ){
      _this.passMsg = "用户名不存在或密码错误"
      return
    }
    _this.passMsg = ""
    // localStorage.setItem("_TOKEN",data.tokenValue)
    // _this.$store.dispatch('GetInfoFn',_this)

    _this.$store.commit('SET_TOKEN',data.tokenValue)
    _this.$store.commit('SET_COUNTNUM',1)
  }
  /**
   * @Author HS
   * @Date 2021/6/29 2:19 下午
   * @Description: 页面跳转
   * @Params: { string ： path - true } [path：路径]
   * @Return:
  */
  goRouter(path:string){
    const _this:any = this;
    let code = _this.$route.query
    for (let codeKey in code) {
      code[codeKey] = _this.$decryptBy(code[codeKey])
    }
    let codeStr = _this.$base.serializeStr(code)
    const link = _this.$base.routeLinkSplice(_this.$LoginConfig[path].path,codeStr)
    _this.$base.goRouter(link)
  }

}
